import {
  Box,
  Stack,
  Typography,
  TextField,
  Collapse,
  Alert,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { startOfDay } from 'date-fns';
import { de } from 'date-fns/locale';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Actions from '../../../components/Actions';
import DropdownWithHeadline from '../../../components/DropdownWithHeadline';
import TextFieldWithLabel from '../../../components/TextFieldWithLabel';

const FORMAT_PRESENCE = '1';
const FORMAT_ONLINE = '2';

const Page4 = ({ onBack, onNext, data }) => {
  // TODO: Implement a form here using react-hook-form to store this state with yup validation
  const [date, setDate] = useState(data.date ?? null);
  const [time, setTime] = useState(data.time);
  const [format, setFormat] = useState(data.format);
  const [room, setRoom] = useState(data.room);
  const [showError, setShowError] = useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="HeadlineH4" component="h1" gutterBottom>
        Bitte tragen Sie hier Datum, Uhrzeit und Format Ihres Workshops ein, die
        Sie im Vorfeld mit Ihrem Agile Master oder Coach abgestimmt haben.
      </Typography>
      <Collapse in={showError}>
        <Alert variant="filled" severity="error">
          Bitte alle Felder ausfüllen
        </Alert>
      </Collapse>
      <Stack
        direction="row"
        spacing={12}
        sx={{ width: '588px', margin: 'auto', marginTop: 12 }}
      >
        <LocalizationProvider locale={de} dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="month"
            value={date}
            onChange={(newValue) => {
              setDate(startOfDay(newValue));
            }}
            views={['day']}
            renderInput={(params) => <TextField {...params} />}
            disableHighlightToday
            minDate={startOfTomorrow()}
          />
        </LocalizationProvider>
        <Stack spacing={4}>
          <DropdownWithHeadline
            onChange={(e) => {
              setTime(e.target.value);
            }}
            value={time}
            options={[
              { label: 'Vormittags (8 Uhr)', value: '8' },
              { label: 'Nachmittag (13 Uhr)', value: '13' },
            ]}
            title="Geplante Uhrzeit"
          />
          <DropdownWithHeadline
            onChange={(e) => {
              const { text, value } = e.target.selectedOptions[0];
              setFormat({ label: text, value });
              if (value === FORMAT_ONLINE) {
                setRoom('');
              }
            }}
            value={format?.value}
            options={[
              { label: 'Online', value: FORMAT_ONLINE },
              { label: 'Präsenz', value: FORMAT_PRESENCE },
            ]}
            title="Soll der Workshop online oder in Präsenz stattfinden?"
          />
          {format?.value === FORMAT_PRESENCE ? (
            <TextFieldWithLabel
              label="Bitte buchen Sie einen Raum und geben die Raumnummer an"
              labelPosition="top"
              value={room}
              onChange={(e) => {
                setRoom(e.target.value);
              }}
            />
          ) : null}
        </Stack>
      </Stack>
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Actions
          hasBackButton
          onBackButtonClick={() => onBack()}
          hasNextButton
          onNextButtonClick={() =>
            date &&
            time &&
            (format?.value === FORMAT_ONLINE ||
              (format?.value === FORMAT_PRESENCE && room))
              ? onNext({ date, time, format, room })
              : setShowError(true)
          }
        />
      </Box>
    </Box>
  );
};

Page4.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  data: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
    format: PropTypes.string,
    room: PropTypes.string,
  }).isRequired,
};

export { FORMAT_ONLINE, FORMAT_PRESENCE };
export default Page4;
